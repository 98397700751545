import Message from "./index";

/**
 * Generic error - form validation
 */
export const GenericErrorForm = () =>
  Message.error("Fields marked as * are required.");

/**
 * Generic error - Email validation
 */
export const EnterValidEmail = () =>
  Message.error("Please enter a valid email address.");

/**
 * Generic success - Update
 */
export const GenericSuccessUpdate = () =>
  Message.success("Data updated successfully.");

/**
 * Generic error - Update
 */
export const UnableToUpdate = () =>
  Message.error("Unable to update data at this time. Please try again later.");

/**
 * Generic error - Fetch
 */
export const UnableToFetchData = () =>
  Message.error(`Unable to get data at the moment.`);

/**
 * Generic error - Create
 */
export const GenericError = () =>
  Message.error("Something went wrong. Please try again later.");

/**
 * Generic error - Session Unauth
 */
export const Unauthenticated = () =>
  Message.error("Session expired. Please login.");

/**
 * Generic create - Success
 */
export const CreatedSuccessFully = () =>
  Message.success("Created successfully.");

/**
 * Generic delete - Success
 */
export const DeletedSuccessfully = () =>
  Message.success("Deleted successfully.");

/**
 * Join waitlist success
 */
export const WaitlistJoined = () =>
  Message.success(
    "Thank you for registering with us. We will reach out to you shortly."
  );

/**
 * Flash card email success
 */
export const FlashCardEmailSent = () =>
  Message.success(
    "Hey! We mailed you the plant details along with our expert guidance. Happy growing! 🌱"
  );

/**
 * Flash card email failed
 */
export const FlashCardEmailFail = () =>
  Message.error(
    "Sorry we could not email you the details, can you please try again. Or contact support at eva@naturethings.co"
  );

/**
 * Subscription success
 */
export const SubscriptionSuccessful = () =>
  Message.success(
    "Hey! We added the plant to your subscription list. Please check your WhatsApp. Happy growing! 🌱"
  );

/**
 * Subscription failure
 */
export const SubscriptionFail = () =>
  Message.success(
    "Sorry, we were not able to add the plant to your list, please try again."
  );

/**
 * PLant match message success
 */
export const PLantMatchMessageSent = () =>
  Message.success(
    "Hey! We messaged your plant match to your WhatsApp. Happy growing! 🌱"
  );

/**
 * Invalid Otp
 */
export const InvalidOtp = () => Message.error("Invalid OTP, please try again.");
