import React, { useContext, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { destroyCookie } from "nookies";

import { useQuery } from "@apollo/client";
import { GET_ORG_PLAN_DATA } from "@operations/user/queries";

import {
  Menu,
  MenuItem as MuiMenuItem,
  // ListItemIcon,
  IconButton,
  Button,
  useMediaQuery,
  Link as MuiLink,
  ListItem,
  Tooltip,
} from "@mui/material";
import Image from "next/image";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { useTheme } from "@mui/material/styles";

import styled from "@emotion/styled";

import { AuthDialogContext } from "../../../contexts/AuthDialogContext";
import { logout } from "@operations/auth";
import { GET_USER_ORG } from "../../../operations/user/queries";

const MenuItem = styled(MuiMenuItem)`
  font-weight: bold;
  font-size: 15px;
`;

const AvatarIconButton = styled(IconButton)`
  border-radius: 12px;
  background-color: transparent;
`;

export default function OrgAccountMenu() {
  const { t } = useTranslation("common");

  const { data: { getOrgSubscriptionInfo: planData } = {} } = useQuery(
    GET_ORG_PLAN_DATA,
    {
      fetchPolicy: "network-only",
    }
  );

  const { data: { userOrg: orgData } = {} } = useQuery(GET_USER_ORG, {
    fetchPolicy: "network-only",
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const router = useRouter();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { setAuthDialog } = useContext(AuthDialogContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    destroyCookie(null, "token");
    setAuthDialog(false);
    router.push("/login"); // redirecting to login
  };

  const menuItems = [
    {
      label: t("my_profile"),
      path: "/org/profile",
    },
    {
      label: t("dashboard"),
      path: `/org/dashboard?spaceId=${orgData?.spaceId}`,
    },
    // {
    //   label: t("digital_garden"),
    //   path: "/org/plants",
    // },
    // {
    //   label: t("maintenance"),
    //   path: "/org/maintenance",
    //   enabled: "NO",
    // },
    // {
    //   label: t("org_wellness"),
    //   path: "/org/wellness",
    //   enabled: "NO",
    // },
    // {
    //   label: t("rewards"),
    //   path: "/org/rewards",
    //   enabled: "NO",
    // },
    // {
    //   label: t("plan"),
    //   path: "/org/plan",
    // },
    {
      label: t("files"),
      path: "/org/files",
      enabled: "YES",
    },
  ];

  const styles = {
    menuItem: {
      fontSize: "18px",
      lineHeight: "28px",
      fontWeight: "600",
      textDecoration: "none",
      // margin: "0.5rem",
      color: "primary.purple",
      // padding: "10px 12px",
      borderTopLeftRadius: "17px 15px",
      borderTopRightRadius: "70px 22px",
      borderBottomRightRadius: "30px 15px",
      borderBottomLeftRadius: "20px 27px",
      opacity: 1,
      "&:hover": {
        backgroundColor: "secondary.linen",
      },
    },
    active: {
      color: "common.white",
      backgroundColor: "primary.purple",
      "&:hover": {
        backgroundColor: "primary.purple",
      },
    },
  };

  return (
    <>
      <AvatarIconButton onClick={handleClick} size="small">
        <Image
          width={20}
          height={20}
          src="/assets/images/profile_menu/profile_icon.svg"
          alt="Profile"
        />
        <KeyboardArrowDownIcon />
      </AvatarIconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            padding: "9px 11px",
            borderRadius: "12px",
            boxShadow: "0 12px 24px 0 rgba(0, 0, 0, 0.05)",
            border: "solid 2px #e3e3e3",
            backgroundColor: "#fff",
          },
        }}
        transformOrigin={{
          horizontal: isMobile ? "center" : "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: isMobile ? "center" : "right",
          vertical: "bottom",
        }}
      >
        {menuItems.map((item, index) => (
          <ListItem
            key={`verticalNav-${index}`}
            sx={{ justifyContent: "center" }}
          >
            <Tooltip
              key={index}
              title={
                !planData?.id && item.enabled != "YES" ? (
                  <h1>Subscribe to our free trial to unlock</h1>
                ) : (
                  item.label
                )
              }
            >
              <span>
                <MuiLink
                  sx={{
                    ...styles.menuItem,
                    ...(item.path === router.pathname && styles.active),
                  }}
                  href={item.path}
                  component={Button}
                  underline="none"
                  disabled={
                    item.enabled ? !(item.enabled == "YES") : !planData?.id
                  }
                >
                  {item.label}
                </MuiLink>
              </span>
            </Tooltip>
          </ListItem>
        ))}
        <MenuItem
          sx={{
            "&:hover": {
              backgroundColor: "unset",
            },
          }}
        >
          <Button
            sx={{
              color: "#df4141",
            }}
            variant="contained"
            onClick={handleLogout}
            color="inherit"
            fullWidth
          >
            Logout
          </Button>
        </MenuItem>
      </Menu>
    </>
  );
}
