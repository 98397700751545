import { auth } from "../../../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  EmailAuthProvider,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  getAuth,
} from "firebase/auth";
import Message from "../../components/common/Message";
import { GenericError } from "../../components/common/Message/messages";
import { apolloMutation } from "../../apolloConfig/helpers";
import { CREATE_USER } from "./mutations";

const login = async ({ email, password }) => {
  try {
    let result = await signInWithEmailAndPassword(auth, email, password);
    if (result && result.user) {
      await createUser(result.user.accessToken);
      return result.user;
    } else {
      Message.error("User authentication failed. Please try again.");
      return null;
    }
  } catch (e) {
    Message.error("Please check your credentials.");
    return null;
  }
};

const signupWithUsernameAndPassword = async ({ email, password }) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    if (res.user) {
      await createUser(res.user.accessToken);
      return true;
    }
  } catch (e) {
    GenericError();
  }
};

const signInWithGoogle = async () => {
  try {
    const provider = new GoogleAuthProvider();
    const signInRes = await signInWithPopup(auth, provider);
    if (signInRes) {
      const token = signInRes.user.accessToken;
      if (signInRes._tokenResponse.isNewUser) {
        await createUser(token);
      }
    }
  } catch (e) {
    GenericError();
  }
};

const signInWithFacebook = async () => {
  try {
    const provider = new FacebookAuthProvider();
    const signInRes = await signInWithPopup(auth, provider);
    if (signInRes) {
      const token = signInRes.user.accessToken;
      if (signInRes._tokenResponse.isNewUser) {
        await createUser(token);
      }
    }
  } catch (e) {
    console.warn(e);
    GenericError();
  }
};

const initiateRecaptcha = (ref) => {
  return new Promise((resolve) => {
    const _auth = getAuth();
    _auth.useDeviceLanguage();
    window.recaptchaVerifier = new RecaptchaVerifier(
      ref,
      {
        size: "invisible",
        callback: () => {},
        "expired-callback": () => {
          // window.location.reload();
        },
      },
      _auth
    );

    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
      resolve();
    });
  });
};

const signInSignUpWithPhoneNumber = async ({ phoneNumber }) => {
  try {
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        //  window.location.reload();
        console.log(error);
      });
  } catch (e) {
    console.warn(e);
    GenericError();
  }
};

const verifyOtp = (otp) => {
  return new Promise((resolve, reject) => {
    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        resolve(result);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const changePassword = async ({ currentPassword = "", newPassword = "" }) => {
  try {
    const user = auth.currentUser;
    const credential = await EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    const validateCurrentPassword = await reauthenticateWithCredential(
      user,
      credential
    );
    if (validateCurrentPassword) {
      await updatePassword(user, newPassword);
      return { status: true, message: "Successful" };
    }
  } catch (e) {
    console.warn(e);
    GenericError();
    return { status: false, message: e };
  }
};

const logout = async () => {
  await signOut(auth);
};

const createUser = async (token) => {
  await apolloMutation(CREATE_USER, { token });
};

export {
  login,
  signupWithUsernameAndPassword,
  logout,
  signInWithGoogle,
  signInWithFacebook,
  changePassword,
  signInSignUpWithPhoneNumber,
  initiateRecaptcha,
  verifyOtp,
  createUser,
};
