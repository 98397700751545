import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../../firebase";
import nookies, { parseCookies } from "nookies";
import { useContext } from "react";
import { AuthDialogContext } from "../../../contexts/AuthDialogContext";
import { Typography } from "@mui/material";

const useAuthAction = () => {
  const { setAuthDialog, setAuthDialogData } = useContext(AuthDialogContext);

  return (query = () => {}, params = undefined) => {
    const unauthRoutes = ["/login", "/signup"];
    const cookies = parseCookies();

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (!cookies?.token) {
          const token = user.accessToken;
          nookies.set(undefined, "token", token);
        }
        setAuthDialog(false);
        query(params);
      } else {
        if (!unauthRoutes.includes(window.location.pathname)) {
          setAuthDialogData({
            showCloseButton: true,
            additionalElement: (
              <Typography color="primary.main" variant="h5Inter">
                Login to your account
              </Typography>
            ),
          });
        }
      }
    });
  };
};

export default useAuthAction;
