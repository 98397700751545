import client from "./index";

const apolloQuery = async (query, options = {}, context) => {
  return await client.query({
    query,
    context,
    ...options,
  });
};

const apolloMutation = async (mutation, variables = {}, context) => {
  return await client.mutate({
    mutation,
    variables,
    context,
  });
};

export { apolloQuery, apolloMutation };
