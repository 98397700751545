import { gql } from "@apollo/client";

const CREATE_USER = gql`
  mutation CreateUser($token: String!) {
    createUser(token: $token) {
      id
      email
    }
  }
`;

export { CREATE_USER };
